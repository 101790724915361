import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button } from '../../components/buttons';
import iconZoom from '../../img/zoom.png';
import {
  selectUserIntegrationConnections,
  selectUserSettings,
} from '../../redux/selectors';
import { RootState } from '../../redux/store';
import { AlertExtensionIsNotInstalled } from './AlertExtensionIsNotInstalled';
import { AlertNeedMorePermissions } from './AlertNeedMorePermissions';
import { AlertNeedZoomWCRedirect } from './AlertNeedZoomWCRedirect';
import { AlertZoomWebSettingsInfo } from './AlertZoomWebSettingsInfo';
import { OnboardingBlock } from './OnboardingBlock';
import { PermissionsGrantedStatus } from '../../redux/modules/global';
import { trackSetupHelperButton } from '../../helpers/analytics';
import { MainBlockTypes } from '.';
import featureFlagService from '../../helpers/feature-flags';
import { AlertDownloadMacApp } from './AlertDownloadMacApp';

export const OnboardingZoom: React.FC<{
  isLarge: boolean;
  mainBlock: MainBlockTypes;
  isZoomSelected: boolean;
}> = ({ isLarge, mainBlock, isZoomSelected }) => {
  const showMacAppDownloadCard =
    isZoomSelected &&
    featureFlagService.isMacAppMvpRolloutEnabled() &&
    navigator.userAgent.toLowerCase().includes('mac');
  const isExtensionUnavailable = !useSelector(
    (state: RootState) => state.global.isExtensionAvailable
  );
  const arePermissionsGranted = useSelector(
    (state: RootState) => state.global.integrations?.arePermissionsGranted
  );
  const isRedirectEnabled = useSelector(selectUserSettings).zoomwcredirect;
  const connection = useSelector(selectUserIntegrationConnections)?.find(
    (c) => c.type === 'zoomapi'
  );
  const intl = useIntl();

  let webEnabled = false;
  const alerts = [];

  if (showMacAppDownloadCard) {
    alerts.push(<AlertDownloadMacApp />);
  } else {
    if (isExtensionUnavailable) {
      alerts.push(<AlertExtensionIsNotInstalled />);
      webEnabled = false;
    } else if (arePermissionsGranted === PermissionsGrantedStatus.MISSING) {
      alerts.push(<AlertNeedMorePermissions />);
      webEnabled = false;
    } else if (!isRedirectEnabled) {
      alerts.push(<AlertNeedZoomWCRedirect />);
      webEnabled = false;
    } else {
      webEnabled = true;
    }
  }

  if (connection || webEnabled) {
    alerts.push(<AlertZoomWebSettingsInfo />);
  }

  // if (connection) {
  //   alerts.push(<AlertZoomDesktopRequirementsInfo />);
  // } else {
  //   const Connector = createOAuthConnector(
  //     'zoom',
  //     'Zoom Desktop',
  //     <>Connect</>,
  //     'warning',
  //     true
  //   );
  //   alerts.push(
  //     <Alert
  //       severity="warning"
  //       sx={{ alignItems: 'center' }}
  //       action={<Connector />}
  //     >
  //       <FormattedMessage defaultMessage="Desktop app available" id="Vsui3B" />
  //     </Alert>
  //   );
  // }

  return (
    <OnboardingBlock
      isLarge={isLarge || showMacAppDownloadCard}
      icon={iconZoom}
      title="Zoom"
      alerts={alerts}
      button={
        !showMacAppDownloadCard && (
          <Button
            target="_blank"
            href="https://zoom.us/start/webmeeting"
            onClick={() => {
              if (!webEnabled && !connection) {
                enqueueSnackbar(
                  intl.formatMessage({
                    defaultMessage: 'Please configure Tactiq and Zoom first',
                    id: 'jUSPsy',
                  }),
                  { variant: 'WARNING' }
                );
              }
              trackSetupHelperButton('zoom', mainBlock);
            }}
          >
            Try in Zoom
          </Button>
        )
      }
    />
  );
};
