import React from 'react';
import { FormattedMessage } from 'react-intl';
import { trackWebEvent } from '../../helpers/analytics';
import { Button } from '../../components/buttons';
import { Alert } from '../../components/Alert';
import { Chip } from '../../components/Chips';
import { Tooltip } from '../../components/Tooltip';
import { Lock } from 'lucide-react';

type SettingsInfoProps = {
  title: string;
  description?: React.ReactNode;
  helpLink?: {
    condition?: boolean;
    message: React.ReactNode;
    url: string;
  };
  showLock?: boolean;
};

type RequiresUpgadeProps = {
  condition: boolean;
  prompt?: React.ReactNode;
  action: () => void;
  chipMessage?: React.ReactNode;
  reason: string;
};

export const SettingsRow: React.FC<{
  settingInfo: SettingsInfoProps;
  settingAction: React.ReactNode;
  overriddenByTeam?: boolean;
  requiresUpgrade?: RequiresUpgadeProps;
}> = ({ settingInfo, settingAction, requiresUpgrade, overriddenByTeam }) => {
  function handleUpgradeClick() {
    if (!requiresUpgrade) return;
    requiresUpgrade.action();
    trackWebEvent('Settings - Upgrade chip clicked', {
      setting: settingInfo.title,
      reason: requiresUpgrade.reason,
    });
  }
  const shouldShowHelpLink = settingInfo.helpLink?.condition ?? true;
  if (requiresUpgrade?.condition) {
    return (
      <li className="flex list-none flex-row items-center justify-between gap-4 py-5 md:gap-6">
        <div className="flex flex-col items-start gap-1.5">
          <div className="flex flex-row items-center gap-2">
            <div className="font-semibold text-lg text-slate-900 leading-6">
              {settingInfo.title}
            </div>
            {requiresUpgrade.condition && (
              <Tooltip arrow title={requiresUpgrade.reason} placement="top">
                <Chip type="upgrade" onClick={() => handleUpgradeClick()}>
                  {requiresUpgrade.chipMessage}
                </Chip>
              </Tooltip>
            )}
          </div>
          {settingInfo.description && (
            <div className="text-slate-600 text-sm leading-5">
              {settingInfo.description}
            </div>
          )}
        </div>
        <Button
          variant="neutral-secondary"
          size="small"
          upgrade
          onClick={() => handleUpgradeClick()}
        >
          <FormattedMessage defaultMessage="Upgrade" />
        </Button>
      </li>
    );
  }
  return (
    <li className="flex list-none flex-row justify-between gap-4 py-5 md:gap-6">
      <div className="min-w-0 items-center">
        <div className="flex flex-col items-start gap-1.5">
          <div className="flex flex-row items-center gap-2 font-semibold text-lg text-slate-800 leading-6">
            {settingInfo.showLock ? (
              <Tooltip
                placement="top"
                title={
                  <FormattedMessage defaultMessage="This setting is controlled by your team admin. If you would like it changed you will need to contact them." />
                }
              >
                <Lock className="size-4" />
              </Tooltip>
            ) : null}
            {settingInfo.title}
          </div>

          {settingInfo.description && (
            <div className="mt-1 text-slate-600 text-sm leading-5">
              {settingInfo.description}
            </div>
          )}
          {shouldShowHelpLink &&
            settingInfo.helpLink?.message &&
            settingInfo.helpLink?.url && (
              <Alert
                variant="light"
                action={
                  <Button
                    href={settingInfo.helpLink.url}
                    size="text"
                    target="_blank"
                    variant="text"
                    onClick={() => {
                      trackWebEvent(
                        'Clicked article link – Go to Help Center article',
                        {
                          article: settingInfo.helpLink?.url,
                        }
                      );
                    }}
                  >
                    <FormattedMessage defaultMessage="Read the article" />
                  </Button>
                }
                description={settingInfo.helpLink.message}
                severity="info"
              />
            )}
          {overriddenByTeam ? (
            <Alert
              variant="light"
              description={
                <FormattedMessage defaultMessage="This setting is controlled by your team administrator." />
              }
              severity="warning"
            />
          ) : null}
        </div>
      </div>
      <div className="flex items-center">{settingAction}</div>
    </li>
  );
};
