import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ArrowDownToLine, FileClock, Laptop } from 'lucide-react';
import { Button } from '../../components/buttons';
import {
  trackDownloadMacApp,
  trackDownloadMacAppOptionViewed,
  trackWaitlistIntelMacApp,
  trackWaitlistWindowsApp,
} from '../../helpers/analytics';
import { isProduction, projectId } from '../../helpers/firebase/config';
import { ModalDialog } from '../../components/modals';
import AsyncButton from '../../components/buttons/AsyncButton';

const getDownloadUrl = () =>
  isProduction()
    ? 'https://downloads.tactiq.io/companion-macos/io.tactiq.macosapp.release.0.1.2923.dmg'
    : `https://downloads.review.tactiq.io/${projectId}/companion-macos/latest-beta.dmg`;

export const AlertDownloadMacApp = () => {
  const [showMacChipHelpModal, setShowMacChipHelpModal] = useState(false);
  const [showWaitlistModal, setShowWaitlistModal] = useState(false);
  useEffect(() => {
    trackDownloadMacAppOptionViewed({});
  }, []);
  return (
    <>
      <div className="mt-4 flex flex-col rounded-xl bg-gradient-to-b from-brand-500 to-brand-800 p-6 text-white">
        <div className="mb-6 flex items-start justify-between">
          <div className="inline-block rounded-full bg-white p-3 text-black">
            <Laptop size="1.5rem" />
          </div>
          <div className="inline-flex items-center rounded-full bg-pink-100 px-2.5 py-1 font-medium text-pink-700 text-xs">
            BETA
          </div>
        </div>

        <div>
          <p className="text-xs">Desktop</p>
          <p className="mb-2 font-bold text-xl">MacOS</p>
          <p className="mb-6 text-md">
            <FormattedMessage defaultMessage="Transcribe your Zoom desktop meetings with our new MacOS app. Download now!" />
          </p>
        </div>
        <Button
          href={getDownloadUrl()}
          target="_blank"
          variant="neutral-secondary"
          startIcon={<ArrowDownToLine size="1rem" />}
          size="small"
          onClick={trackDownloadMacApp}
          fullWidth
        >
          <FormattedMessage defaultMessage="Download for MacOS - Apple Silicon" />
        </Button>

        <p className="mt-4 text-center text-xs">
          <FormattedMessage
            defaultMessage="How to check what {info}"
            values={{
              info: (
                <button
                  onClick={() => setShowMacChipHelpModal(true)}
                  className="cursor-pointer underline"
                >
                  <FormattedMessage defaultMessage="chip your Mac uses" />
                </button>
              ),
            }}
          />
        </p>
        {showMacChipHelpModal && (
          <CheckMacChipModal onClose={() => setShowMacChipHelpModal(false)} />
        )}
      </div>
      <div className="mt-4">
        <Button
          variant="neutral-secondary"
          startIcon={<FileClock size="1rem" />}
          size="small"
          onClick={() => setShowWaitlistModal(true)}
          fullWidth
        >
          <FormattedMessage defaultMessage="Not on Apple Silicon Mac? Join waitlist!" />
        </Button>
      </div>
      {showWaitlistModal && (
        <WaitlistModal onClose={() => setShowWaitlistModal(false)} />
      )}
    </>
  );
};

const WaitlistModal = (props: { onClose: () => void }) => (
  <ModalDialog
    size="medium"
    open
    onClose={props.onClose}
    title={<FormattedMessage defaultMessage="Join waitlist for" />}
    text={
      <div className="mt-2 space-y-2">
        <AsyncButton
          onClick={() => trackWaitlistIntelMacApp({})}
          afterSuccess={() => props.onClose()}
          variant="neutral-secondary"
          fullWidth
        >
          Intel Mac
        </AsyncButton>
        <AsyncButton
          onClick={() => trackWaitlistWindowsApp({})}
          afterSuccess={() => props.onClose()}
          variant="neutral-secondary"
          fullWidth
        >
          Windows
        </AsyncButton>
      </div>
    }
  />
);

const CheckMacChipModal = (props: { onClose: () => void }) => {
  return (
    <ModalDialog
      size="medium"
      open
      onClose={props.onClose}
      title={<FormattedMessage defaultMessage="How to check your Mac's Chip" />}
      text={
        <ol className="mt-2 list-inside list-decimal space-y-2 text-black">
          <li>
            <FormattedMessage defaultMessage="At the top left, open the Apple menu and select About This Mac." />
          </li>
          <li>
            <FormattedMessage defaultMessage="Look for “Processor” or “Chip” and check if it says “Intel” or “Apple”." />
          </li>
          <li>
            <FormattedMessage defaultMessage="If it says “Apple” the Tactiq app will run on your Mac." />
          </li>
        </ol>
      }
    />
  );
};
