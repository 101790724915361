import React from 'react';
import { CenteredPanel } from '../components/panels/centered';
import { Button } from '../components/buttons';
import { fetchApiV2 } from '../helpers/api/helpers';
import TactiqLogomark from '../img/tactic-logomark.svg';
import { FormattedMessage } from 'react-intl';

export const DesktopAuthLanding: React.FC = function () {
  return (
    <CenteredPanel>
      <div className="flex flex-col items-center gap-y-6 p-8 sm:max-w-lg">
        <img
          src={TactiqLogomark}
          alt="Tactiq logo"
          className="h-24 w-24 self-center"
        />
        <div className="flex flex-col items-center gap-y-4">
          <h1 className="font-bold text-4xl tracking-tighter">
            <FormattedMessage defaultMessage="Welcome to Tactiq" />
          </h1>
          <p className="text-center text-neutral-tertiary">
            <FormattedMessage
              defaultMessage="Tactiq is a meeting productivity tool that helps you take notes,
            manage tasks, and track decisions in real-time."
            />
          </p>
        </div>
        <div className="flex gap-4">
          <Button
            size="large"
            variant="neutral-primary"
            onClick={async () => {
              const result = await fetchApiV2<{ token: string }>(
                '/a/user/custom-token'
              );

              if (result && !('error' in result) && result.token) {
                // redirect to app
                window.location.href = `tactiq://desktop-auth?token=${result.token}`;
                return;
              }
              alert('Error generating token');
            }}
            className="w-70"
          >
            <FormattedMessage defaultMessage="Sign in to Desktop App" />
          </Button>
        </div>
      </div>
    </CenteredPanel>
  );
};
