import { logger } from '@tactiq/model';
import { Recycle } from 'lucide-react';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAsyncEffect } from 'use-async-effect';
import { TextInput } from '../../../components/TextInput';
import { Button } from '../../../components/buttons';
import { Team, TeamInvitationLink } from '../../../graphql/operations';
import { trackWebEvent } from '../../../helpers/analytics';
import { createTeamInvitationLink } from '../../../helpers/api';
import { baseURL } from '../../../helpers/firebase/config';
import { Alert } from '../../../components/Alert';
import AsyncButton from '../../../components/buttons/AsyncButton';

export interface TeamInvitationLinkUIProps {
  team: Team;
  isAdmin: boolean;
}

/**
 * Team invitation link
 * @param {unknown} param0 params
 * @param {Team} param0.team team
 * @returns {React.FC} a component
 */
export const TeamInvitationLinkUI: React.FC<TeamInvitationLinkUIProps> = ({
  team,
  isAdmin,
}) => {
  const intl = useIntl();
  const [isCreatingLink, setIsCreatingLink] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrored, setHasErrored] = useState(false);

  const [teamInvitationLink, setTeamInvitationLink] = useState<
    TeamInvitationLink | undefined
  >(team.invitationLink);

  const onCreateLink = useCallback(async () => {
    trackWebEvent('Team View - Invitation link create', { team_id: team?.id });
    setIsLoading(true);
    try {
      const res = await createTeamInvitationLink(team.id);
      setTeamInvitationLink(res);
    } catch (e) {
      logger.error(e);
      setHasErrored(true);
    }

    setIsLoading(false);
  }, [team]);

  useAsyncEffect(async () => {
    if (
      !team.invitationLink &&
      !isCreatingLink &&
      !teamInvitationLink &&
      isAdmin
    ) {
      setIsCreatingLink(true);

      try {
        await onCreateLink();
      } catch (err) {
        logger.error(err);
        enqueueSnackbar(
          intl.formatMessage({
            defaultMessage:
              'Could not create team invitation link. Please chat with support.',
            id: 'RE6bKq',
          }),
          { variant: 'WARNING' }
        );
      }
    }
  }, [team, onCreateLink, isCreatingLink, intl]);

  const onCopyInvitationLink = useCallback(() => {
    if (!teamInvitationLink) {
      return;
    }

    trackWebEvent('Team View - Invitation link copied', { team_id: team?.id });
    navigator.clipboard
      .writeText(`${baseURL}/x/u/l/${teamInvitationLink.id}?type=team`)
      .catch(logger.error);
  }, [team, teamInvitationLink, intl]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row">
        <h3 className="flex-1">
          <FormattedMessage
            defaultMessage="Share your link"
            description="Team invitation link. Title."
            id="JZm2Xe"
          />
        </h3>
      </div>
      {hasErrored && (
        <Alert
          severity="error"
          description={intl.formatMessage({
            defaultMessage:
              'Could not reset team invitation link. Please chat with support.',
          })}
        />
      )}

      <div className="flex flex-col gap-4">
        <div className="flex flex-row gap-2">
          <div className="flex-1 border border-slate-300 border-dashed">
            <TextInput
              variant="naked"
              disabled
              value={
                teamInvitationLink
                  ? `${baseURL}/x/u/l/${teamInvitationLink?.id}?type=team`
                  : 'No invitation link available'
              }
              inputProps={{
                onFocus: (event) => event.target.select(),
              }}
            />
          </div>
          <AsyncButton onClick={onCopyInvitationLink}>
            <FormattedMessage
              defaultMessage="Copy"
              description="Team invitation link. Copy button title."
              id="88JR1x"
            />
          </AsyncButton>
          {isAdmin && (
            <Button
              variant={isAdmin ? 'neutral-secondary' : 'neutral-tertiary'}
              loading={isLoading}
              onClick={() => onCreateLink()}
              startIcon={<Recycle className="h-4 w-4" />}
            >
              <FormattedMessage
                defaultMessage="Reset"
                id="8w3RRH"
                description="Team invitation link. Re-generate button tooltip text."
              />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
