import { useMutation } from '@apollo/client';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { Button } from '../../components/buttons';
import {
  AssignTeamSeatDocument,
  RequestUpgradeDocument,
  Team,
  TeamTier,
} from '../../graphql/operations';
import {
  trackJoinTeamClaimSeat,
  trackJoinTeamPage,
  trackJoinTeamSeatSkip,
  trackJoinTeamSeatUpgrade,
} from '../../helpers/analytics';
import { showJoinEnterpriseTeamContent } from '../../services/Team';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

export const TeamJoin: React.FC<{ team: Team }> = ({ team }) => {
  const navigate = useNavigate();
  const uid = useSelector(({ user }: RootState) => user.id);
  const [requestUpgrade, requestUpgradeMutation] = useMutation(
    RequestUpgradeDocument
  );
  const [assignTeamSeat, assignTeamSeatMutation] = useMutation(
    AssignTeamSeatDocument
  );

  React.useEffect(() => {
    trackJoinTeamPage(team.id);
  }, [team.id]);

  if (!team) navigate('/setup');

  // We run this check currently just for Netflix but could be extended for more enterprise customers where a user can claim a seat.
  if (showJoinEnterpriseTeamContent(team.id)) {
    return (
      <div className="mx-auto flex max-w-7xl justify-center p-8">
        <div className="flex flex-col gap-8 p-10">
          <h1 className="text-3xl">
            <FormattedMessage
              defaultMessage="Welcome to team {name}!"
              values={{
                name: team.name ?? team.displayName,
              }}
            />
          </h1>
          <div className="flex flex-col gap-4">
            <p>
              <FormattedMessage
                defaultMessage="Your team is on an {planType} plan."
                values={{
                  planType: (
                    <b>
                      <FormattedMessage defaultMessage="Enterprise" />
                    </b>
                  ),
                }}
              />{' '}
            </p>

            <p>
              <FormattedMessage
                defaultMessage="To unlock {unlimited} claim your paid seat now."
                values={{
                  unlimited: (
                    <b>
                      <FormattedMessage defaultMessage="unlimited AI credits and meeting transcripts," />
                    </b>
                  ),
                }}
              />
            </p>
          </div>
          <div className="flex flex-row gap-4">
            <Button
              fullWidth
              variant="neutral-secondary"
              size="large"
              disabled={assignTeamSeatMutation.loading}
              onClick={() => {
                trackJoinTeamSeatSkip(team.id);
                navigate('/setup');
              }}
            >
              <FormattedMessage defaultMessage="Skip" />
            </Button>
            <Button
              fullWidth
              variant="neutral-primary"
              size="large"
              upgrade
              loading={assignTeamSeatMutation.loading}
              onClick={async () => {
                trackJoinTeamClaimSeat(team.id);
                await assignTeamSeat({
                  variables: {
                    input: {
                      memberId: uid,
                    },
                  },
                }).then(() => {
                  navigate('/setup');
                });
              }}
            >
              <FormattedMessage defaultMessage="Claim the Paid Seat" />
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mx-auto flex max-w-7xl justify-center p-8">
      <div className="flex flex-col gap-8 p-10">
        <h1 className="text-3xl">
          <FormattedMessage
            defaultMessage="Welcome to team {name}!"
            values={{
              name: team.name ?? team.displayName,
            }}
          />
        </h1>
        <div className="flex flex-col gap-4">
          <p>
            <FormattedMessage
              defaultMessage="Your team is on {plural} {planType} plan."
              values={{
                plural:
                  team.tier === TeamTier.ENTERPRISE ? (
                    <FormattedMessage defaultMessage="an" />
                  ) : (
                    <FormattedMessage defaultMessage="a" />
                  ),
                planType: (
                  <b>
                    {team.tier === TeamTier.ENTERPRISE ? (
                      <FormattedMessage defaultMessage="Enterprise" />
                    ) : (
                      <FormattedMessage defaultMessage="Team" />
                    )}
                  </b>
                ),
              }}
            />{' '}
          </p>

          <p>
            <FormattedMessage
              defaultMessage="{request} to assign you a paid seat and unlock unlimited AI credits and meeting transcripts."
              values={{
                request: (
                  <b>
                    <FormattedMessage defaultMessage="Request an admin" />
                  </b>
                ),
              }}
            />
          </p>
        </div>
        <div className="flex flex-row gap-4">
          <Button
            fullWidth
            variant="neutral-secondary"
            size="large"
            disabled={requestUpgradeMutation.loading}
            onClick={() => {
              trackJoinTeamSeatSkip(team.id);
              navigate('/setup');
            }}
          >
            <FormattedMessage defaultMessage="Skip" />
          </Button>
          <Button
            fullWidth
            variant="neutral-primary"
            size="large"
            upgrade
            loading={requestUpgradeMutation.loading}
            onClick={async () => {
              trackJoinTeamSeatUpgrade(team.id);
              await requestUpgrade().then(() => {
                navigate('/setup');
              });
            }}
          >
            <FormattedMessage defaultMessage="Request Upgrade" />
          </Button>
        </div>
      </div>
    </div>
  );
};
