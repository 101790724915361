import React, {
  ReactElement,
  ReactNode,
  useState,
  useEffect,
  useRef,
} from 'react';

import {
  Header,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  RowData,
  getPaginationRowModel,
  PaginationState,
} from '@tanstack/react-table';

import {
  ArrowDown,
  ArrowUp,
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from 'lucide-react';
import { TextInput } from '../TextInput';
import { FormattedMessage, useIntl } from 'react-intl';
import { Combobox } from '../Combobox';
import { Menu } from '../Menu';
import { Button } from '../buttons';
import { cx } from '../../helpers/utils';

export type ColumnDefinition<T> = ColumnDef<T>;

declare module '@tanstack/react-table' {
  // the extending only works with the exact same defintion
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    ascLabel?: ReactNode;
    descLabel?: ReactNode;
    filterLabel?: ReactNode;
    align?: 'left' | 'right' | 'center';
    stopEventPropagation?: boolean;
  }
}
const pageSizes = [10, 25, 50, 100];
export const cellPaddingClasses = 'py-3 px-2 pl-5';

function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/**
 * Render large arrays in a tabluar format, with sorting, searching and filtering
 */
export function Table<T>(props: {
  columns: Array<
    ColumnDef<T> & {
      ascLabel?: string;
      descLabel?: string;
    }
  >;
  data: Array<T>;
  search?: boolean;
  actions?: () => ReactNode;
  searchPlaceholder?: string;
  initialPageSize?: number;
  onRowClick?: (row: T) => void;
  autoSize?: boolean;
  cellPadding?: boolean;
}): ReactElement {
  const intl = useIntl();
  const {
    columns,
    autoSize = false,
    data,
    actions,
    search,
    cellPadding = true,
    initialPageSize = pageSizes[0],
    searchPlaceholder = intl.formatMessage({
      defaultMessage: 'Search...',
      id: '0BUTMv',
    }),
  } = props;
  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: initialPageSize,
  });

  const table = useReactTable<T>({
    columns,
    data,
    debugTable: false,
    autoResetPageIndex: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(), // client-side faceting
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    defaultColumn: {
      enableColumnFilter: false,
      enableSorting: false,
      filterFn: (row, columnId, filterValue) => {
        if (filterValue.length === 0) return true;
        return filterValue.some((f: unknown) =>
          new Array<unknown>().concat(row.getValue(columnId)).includes(f)
        );
      },
    },
    state: {
      globalFilter,
      sorting,
      pagination,
    },
  });

  const rowCount = table.getRowCount();
  const prevRowCount = usePrevious(rowCount);
  const lastPageIndex = table.getPageCount() - 1;
  const previousPageIndex = usePrevious(pagination.pageIndex);

  useEffect(() => {
    // if we removed some rows and current page is out of bounds, go to the last page
    if (pagination.pageIndex > lastPageIndex) {
      setPagination((state) => ({
        ...state,
        pageIndex: lastPageIndex,
      }));
      return;
    }

    // if we were on a last page and added more rows, keep it on the last page
    if (
      prevRowCount &&
      prevRowCount < rowCount &&
      previousPageIndex === pagination.pageIndex
    ) {
      const prevDataLastPage = Math.ceil(prevRowCount / pagination.pageSize);
      if (pagination.pageIndex === prevDataLastPage - 1) {
        setPagination((state) => ({
          ...state,
          pageIndex: lastPageIndex,
        }));
      }
    }
  }, [pagination, previousPageIndex, rowCount, prevRowCount, lastPageIndex]);

  const filters = table
    .getHeaderGroups()
    .flatMap((group) => group.headers.filter((ii) => ii.column.getCanFilter()));

  const thClasses = 'py-2 px-3 text-left text-xs font-semibold text-slate-700';
  const borderClasses =
    'whitespace-nowrap max-w-56 truncate border-t text-slate-600 border-slate-200';
  const paddingClasses = cellPadding ? cellPaddingClasses : 'p-0';

  const hasFooter = table
    .getFooterGroups()
    .flatMap(({ headers }) =>
      headers.map(({ column }) => column.columnDef.footer)
    )
    .some(Boolean);

  return (
    <div className="flex flex-col overflow-auto rounded-xl border border-slate-200 shadow-sm">
      {(search || filters.length || actions) && (
        <div className="flex items-center gap-2 border-slate-200 border-b p-4">
          {search && (
            <div className="max-w-md">
              <TextInput
                placeholder={searchPlaceholder}
                onChange={(value) => setGlobalFilter(value)}
              />
            </div>
          )}
          {filters.map((header) => (
            <Filter key={header.id} header={header} />
          ))}
          {actions && <div className="ml-auto">{actions?.()}</div>}
        </div>
      )}
      <table className="w-full">
        <thead className="bg-slate-100/60">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const meta = header.column.columnDef.meta;
                const sort = (desc: boolean) => {
                  table.setSorting([
                    {
                      desc,
                      id: header.id,
                    },
                  ]);
                };
                const content = flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                );
                const canSort = header.column.getCanSort();

                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={cx(thClasses, canSort ? 'relative' : '')}
                  >
                    {canSort ? (
                      <Menu>
                        <Menu.Trigger>
                          <Button size="small" variant="text">
                            <div className="flex items-center gap-2 font-medium text-slate-600 text-xs">
                              {content}
                              {{
                                asc: (
                                  <ArrowUp
                                    className="text-slate-500"
                                    size="13px"
                                  />
                                ),
                                desc: (
                                  <ArrowDown
                                    className="text-slate-500"
                                    size="13px"
                                  />
                                ),
                              }[String(header.column.getIsSorted())] ?? null}
                            </div>
                          </Button>
                        </Menu.Trigger>
                        <Menu.Item
                          icon={<ArrowUp size="1rem" />}
                          onClick={() => sort(false)}
                        >
                          {meta?.ascLabel ?? (
                            <FormattedMessage
                              defaultMessage="Ascending"
                              id="u7djqV"
                            />
                          )}
                        </Menu.Item>
                        <Menu.Item
                          onClick={() => sort(true)}
                          icon={<ArrowDown size="1rem" />}
                        >
                          {meta?.descLabel ?? (
                            <FormattedMessage
                              defaultMessage="Descending"
                              id="aleGqT"
                            />
                          )}
                        </Menu.Item>
                      </Menu>
                    ) : (
                      <div className="px-2 py-1.5 font-medium text-slate-600">
                        {content}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr
                key={row.id}
                className={cx(
                  props.onRowClick && 'cursor-pointer',
                  'group/row bg-white transition-all duration-200 hover:bg-slate-50/60'
                )}
                onClick={() => props.onRowClick?.(row.original)}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      key={cell.id}
                      className={cx(borderClasses, paddingClasses)}
                      align={cell.column.columnDef.meta?.align}
                      style={{
                        width: autoSize ? 'auto' : cell.column.getSize(),
                      }}
                      onClick={(event) => {
                        if (cell.column.columnDef.meta?.stopEventPropagation)
                          event.stopPropagation();
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        {hasFooter && (
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th
                    className={cx(thClasses, borderClasses, paddingClasses)}
                    key={header.id}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>
      {table.getRowCount() > initialPageSize && (
        <div className="flex items-center gap-8 border-slate-200 border-t p-4 text-sm">
          <div className="mr-auto text-slate-600">
            <FormattedMessage
              defaultMessage="Showing {subset} of {total} rows"
              id="ApqIhj"
              values={{
                subset: table.getRowModel().rows.length.toLocaleString(),
                total: table.getRowCount().toLocaleString(),
              }}
            />
          </div>

          <span className="flex items-center gap-1 text-slate-700">
            <FormattedMessage
              defaultMessage="Page {start} of {end}"
              id="QmkUfS"
              values={{
                start: table.getState().pagination.pageIndex + 1,
                end: table.getPageCount(),
              }}
            />
          </span>

          <Combobox
            id={(x) => String(x)}
            name={(x) => String(x)}
            options={pageSizes}
            value={table.getState().pagination.pageSize}
            onChange={(next) => table.setPageSize(next ?? pageSizes[0])}
            multiple={false}
            hideSearch
            keepSelected
            highlightWhenSelected={false}
            label={
              <FormattedMessage defaultMessage="Rows per page" id="VCZBMt" />
            }
          />

          <div className="flex items-center gap-2">
            <Button
              variant="neutral-secondary"
              onClick={() => table.firstPage()}
              disabled={!table.getCanPreviousPage()}
              startIcon={<ChevronsLeft size="1rem" />}
            />
            <Button
              variant="neutral-secondary"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              startIcon={<ChevronLeft size="1rem" />}
            />
            <Button
              variant="neutral-secondary"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              startIcon={<ChevronRight size="1rem" />}
            />
            <Button
              variant="neutral-secondary"
              onClick={() => table.lastPage()}
              startIcon={<ChevronsRight size="1rem" />}
            />
          </div>
        </div>
      )}
    </div>
  );
}

function Filter<Data, Value>({ header }: { header: Header<Data, Value> }) {
  const { getFilterValue, setFilterValue, columnDef } = header.column;
  return (
    <Combobox<string>
      multiple
      value={(getFilterValue() as string[]) ?? []}
      label={
        columnDef.meta?.filterLabel ??
        flexRender(columnDef.header, header.getContext())
      }
      id={(x) => x}
      name={(x) => String(x)}
      onRemove={() => setFilterValue(undefined)}
      onChange={setFilterValue}
      options={[
        // Get a unique set of values, even if the values are arrays
        ...new Set(
          Array.from(header.column.getFacetedUniqueValues().keys()).flat()
        ),
      ].sort((a, b) =>
        String(a).localeCompare(String(b), undefined, {
          sensitivity: 'base',
          numeric: true,
        })
      )}
    />
  );
}
